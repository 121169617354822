import { Injectable } from '@angular/core';
import { environment } from '../../../environments/enviroment';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  from,
  of,
  switchMap,
} from 'rxjs';
import { APIs } from '../configs/apis.config';
import { LangConfig } from '../configs/lang.config';
import { StorageKeys } from '../configs/storage.config';

@Injectable({
  providedIn: 'root',
})
export class CustomLanguageService {
  private apiUrl = environment.apiUrl;
  private apiUrlDev = environment.apiUrlDev;
  languageData = new ReplaySubject<any>(1);
  translations: any[] = [];
  shared = new ReplaySubject(1);
  currentLanguage$: BehaviorSubject<LangConfig> =
    new BehaviorSubject<LangConfig>(LangConfig.EN);
  websiteDirection$: BehaviorSubject<'ltr' | 'rtl'> = new BehaviorSubject<
    'ltr' | 'rtl'
  >('ltr');
  translationMode: BehaviorSubject<'internal' | 'external'> =
    new BehaviorSubject<'internal' | 'external'>('internal');
  constructor() {
    this.getInitalLanguage();
    this.getBaseExternalTranslation();
  }

  changeLanguage(language: LangConfig): void {
    if (this.currentLanguage$.value === language) {
      return;
    }
    this.saveCurrentLanguage(language || LangConfig.EN);
  }
  private saveCurrentLanguage(language: LangConfig) {
    this.currentLanguage$.next(language);
    localStorage.setItem(StorageKeys.language, language);
    this.setWebSiteDirection();
  }

  private getInitalLanguage(): void {
    const currentLanguage: LangConfig = (localStorage.getItem(
      StorageKeys.language,
    ) || LangConfig.AR) as LangConfig;
    this.saveCurrentLanguage(currentLanguage);
  }

  private setWebSiteDirection(): void {
    const direction = this.checkLanguageDirection(this.currentLanguage$.value);
    document.body.dir = direction;
    document.body.parentElement!.dir = direction;
    document.body.firstElementChild!.setAttribute('dir', direction);
    this.websiteDirection$.next(direction);
  }
  getUnAuthTranslation(moduleNumber: number): Observable<any> {
    let url = `${this.apiUrlDev + APIs.Translation.GetUnAuth}?module=${moduleNumber}`;
    // return this.http.get(url);
    return from(fetch(url).then((el: any) => el.json()));
  }

  private checkLanguageDirection(languageIsoCode: string): 'rtl' | 'ltr' {
    if (languageIsoCode === 'ar') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

  private getBaseExternalTranslation(): void {
    this.translationMode
      .pipe(
        switchMap((res) => {
          if (res == 'external') {
            return this.getUnAuthTranslation(63);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((val) => {
        if (val) {
          this.shared.next(val);
          this.translations.push({ id: 63, data: val });
        }
      });
  }
}
